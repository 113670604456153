import { ref } from 'vue';
import { defineStore } from "pinia";
import { NotificationsService, type GetApiNotificationsListResponse, type NotificationDto, type PostApiNotificationsSetAsSeenResponse } from '@/client';

export const useNotificationStore = defineStore("notification", () => {
    const notifications = ref([] as NotificationDto[]);

    async function list() {
        return await NotificationsService.getApiNotificationsList()
        .then((response: GetApiNotificationsListResponse) => {
            if (response != undefined) {
                notifications.value = response;
            }
            return Promise.resolve();
        })
        .catch((error:any) => {
            return Promise.reject(error);
        });
    }

    async function setSeen() {
        return await NotificationsService.postApiNotificationsSetAsSeen()
        .then((response: PostApiNotificationsSetAsSeenResponse) => {
            if (response != undefined) {
                notifications.value.forEach(x => {
                    x.seenOn = new Date().toDateString();
                });
            }
            return Promise.resolve();
        })
        .catch((error:any) => {
            return Promise.reject(error);
        });
    }


    return {
       list,
       setSeen,

       notifications
    };
});