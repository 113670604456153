import { ref } from 'vue';
import { defineStore } from "pinia";
import { CompletedchecksService } from '@/client';

export const useCompletedCheckStore = defineStore("completedcheck", () => {

    async function getCompletedCheck(id:number) {
        return await CompletedchecksService.getApiCompletedchecksById({id})
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error:any) => {
            return Promise.reject(error);
        })
    }

    async function getCompletedChecksByDateRange(from:string, to:string) {
        return await CompletedchecksService.getApiCompletedchecksBydate({from, to})
        .then((response) => {
            return Promise.resolve(response);
        })
        .catch((error:any) => {
            return Promise.reject(error);
        })
    }


    return {
        getCompletedCheck,
        getCompletedChecksByDateRange     
    };
});