import { computed, reactive, ref } from "vue";
import { defineStore } from "pinia";
import VueJwtDecode from "vue-jwt-decode";
import {
  type GetApiUserByIdResponse,
  type GetApiUserExistsByEmailResponse,
  OpenAPI,
  type PostApiTokenSetLocationResponse,
  type PostApiUserForgotPasswordResponse,
  type TokenResponse,
  type PostApiTokenSetTenantResponse,
  type UserDto,
  type GetApiUserResponse,
} from "@/client";
import { TokenService, UserService } from "@/client/";
import type { Jwt } from "@/models/jwt";
import { event } from 'vue-gtag'


export const useUserStore = defineStore("user", () => {
  const users = ref([] as UserDto[]);
  const tenant = ref("");
  const user = ref(JSON.parse(localStorage.getItem("user") || "null"));
  const auth = ref(JSON.parse(localStorage.getItem("auth") || "null"));
  const decodedAuth = ref(
    auth.value != null
      ? (VueJwtDecode.decode(auth.value?.token) as Jwt)
      : JSON.parse("null"),
  );
  const locationId = ref("");

  if (auth.value != null) {
    OpenAPI.TOKEN = auth.value!.token!;
    decodedAuth.value = VueJwtDecode.decode(auth.value!.token) as Jwt;
    locationId.value = decodedAuth.value.lid;
    tenant.value = decodedAuth.value.ten;
    if (decodedAuth.value.exp < Date.now() / 1000) {
      logout();
    }
  }

  function logout() {
    event('logout')
    auth.value = null;
    user.value = null;
    OpenAPI.TOKEN = undefined;
    localStorage.clear();
    window.location.reload();
  }

  const isAuthenticated = computed(() => {
    if (auth.value != null) {
      if (decodedAuth.value.exp < Date.now() / 1000) {
        logout();
        return false;
      }
      OpenAPI.TOKEN = auth.value!.token!;
      return true;
    }
    return false;
  });

  async function changePassword(oldPassword: string, password: string) {
    try {
      return await UserService.postApiUserChangePassword({requestBody: { password: oldPassword, newPassword: password }})
        .then((response: any) => {
          return Promise.resolve(response);
        })
        .catch((error:any) => {
          return Promise.reject(error);
        });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function login(email: string, password: string) {
    return await TokenService.postApiToken({ requestBody: { email, password } })
      .then(async (x: TokenResponse) => {
        event('login', { method: 'Normal' })
        const response = x;
        auth.value = response;
        decodedAuth.value = reactive(
          VueJwtDecode.decode(auth.value.token) as Jwt,
        );
        tenant.value = decodedAuth.value.ten;
        locationId.value = decodedAuth.value.lid;
        localStorage.setItem("auth", JSON.stringify(response));
        OpenAPI.TOKEN = response.token!;

        await getCurrentUser();
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function getCurrentUser() {
    decodedAuth.value = VueJwtDecode.decode(auth.value!.token) as Jwt;
    locationId.value = decodedAuth.value.lid;
    user.value = (await getUser(decodedAuth.value.uid)) as UserDto;
    localStorage.setItem("user", JSON.stringify(user.value));
    return user.value;
  }

  async function getUser(id: string) {
    return await UserService.getApiUserById({ id })
      .then((response: GetApiUserByIdResponse) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function getUsers() {
    return await UserService.getApiUser()
      .then((response: GetApiUserResponse) => {
        users.value = response;
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function forgotPassword(email: string) {
    return await UserService.postApiUserForgotPassword({
      requestBody: { email },
    })
      .then((response: PostApiUserForgotPasswordResponse) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function setLocation(locationId: string) {
    return await TokenService.postApiTokenSetLocation({
      requestBody: {
        id: locationId,
      },
    })
      .then(async (x: PostApiTokenSetLocationResponse) => {
        auth.value = x;
        decodedAuth.value = VueJwtDecode.decode(auth.value.token) as Jwt;
        localStorage.setItem("auth", JSON.stringify(x));
        OpenAPI.TOKEN = x.token;
        getCurrentUser();
        return Promise.resolve(x);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function setTenant(tenantId: string) {
    return await TokenService.postApiTokenSetTenant({requestBody: {
      id: tenantId,
    }})
      .then(async (x: PostApiTokenSetTenantResponse) => {
        auth.value = x;
        decodedAuth.value = VueJwtDecode.decode(auth.value.token) as Jwt;
        tenant.value = decodedAuth.value.ten;
        localStorage.setItem("auth", JSON.stringify(x));
        OpenAPI.TOKEN = x.token;
        getCurrentUser();
        window.location.reload();
        return Promise.resolve(x);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function getRoles() {
    return await UserService.getApiUserRoles()
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function add(newuser:UserDto, password:string) {
    return await UserService.putApiUserSimple({
      requestBody:{
        email: newuser.email,
        firstName: newuser.firstName,
        lastName: newuser.lastName,
        phoneNumber: newuser.phoneNumber,
        role: newuser.roles![0],
        locationIds: newuser.locations != undefined ? newuser.locations.map(x => x.id!) : [],
        password: password,
        username: newuser.email,
        tenantId: decodedAuth.value.tid,
      }
    })
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function update(newuser:UserDto) {
    return await UserService.postApiUser({
      requestBody:{
        id: newuser.id,
        email: newuser.email,
        firstName: newuser.firstName,
        lastName: newuser.lastName,
        phoneNumber: newuser.phoneNumber,
        roles: newuser.roles,
        locations: newuser.locations != undefined ? newuser.locations : []
      }
    })
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function disable(id:string) {
    return await UserService.deleteApiUser({requestBody: {id: id}})
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function exists(email:string) {
    return await UserService.getApiUserExistsByEmail({email: email})
      .then((response: GetApiUserExistsByEmailResponse) => {
        return Promise.resolve(response);
      })
      .catch((error:any) => {
        return Promise.reject(error);
      });
  }

  async function resetPassword(email:string, token:string, password:string) {
    return await UserService.postApiUserResetPassword({
      requestBody: {
        email: email,
        token: token,
        password: password
      }
    })
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  async function setPassword(id:string, password:string) {
    return await UserService.postApiUserPassword({
      requestBody: {
        id: id,
        password: password
      }
    })
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

async function updateLoginOption(option:number) {
  return await UserService.postApiUserOption({requestBody: {id: option}})
  .then((response: any) => {
    getCurrentUser();
    return Promise.resolve(response);
  })
  .catch((error: any) => {
    return Promise.reject(error);
  });
}

  return {
    changePassword,
    login,
    forgotPassword,
    getCurrentUser,
    logout,
    setLocation,
    getUsers,
    getUser,
    getRoles,
    add,
    update,
    exists,
    disable,
    resetPassword,
    setTenant,
    setPassword,
    updateLoginOption,

    isAuthenticated,
    user,
    users,
    auth,
    decodedAuth,
    locationId,
    tenant
  };
});
