import nProgress from 'nprogress';

nProgress.configure({ showSpinner: false, parent: "body" });

const requestProgress = (config: any) => {
    nProgress.start();
    return config;
}

const requestError = (error: any) => {
    nProgress.done();
    //  TODO: error handling
    return Promise.reject(error);
}

export { requestProgress, requestError };