import { ref } from "vue";
import { defineStore } from "pinia";
import { PaymentService, type GetApiPaymentHistoryResponse, type GetApiPaymentStatusResponse, type PaymentHistoryDto, type PostApiPaymentCreateResponse, type PostApiPaymentUpdatepaymentmethodResponse } from "@/client/";

export const usePaymentStore = defineStore("payment", () => {
  const page = ref(0);
  const pageSize = ref(20);
  const totalPages = ref(0);
  const history = ref<PaymentHistoryDto[]>();

  async function createSession(id :string) {
    return await PaymentService.postApiPaymentCreate({requestBody: {subscriptionTypeId: id}})
      .then(async (x: PostApiPaymentCreateResponse) => {
        return Promise.resolve(x.clientSecret!);
      })
      .catch((error: any) => {
        return Promise.reject("");
      });
  } 

  async function updatePaymentMethodSession() : Promise<any> {
    return await PaymentService.postApiPaymentUpdatepaymentmethod()
      .then(async (x: PostApiPaymentUpdatepaymentmethodResponse) => {
        return Promise.resolve(x);
      })
      .catch((error: any) => {
        return Promise.reject("");
      });
  } 

  async function sessionStatus(id :string) {
    return await PaymentService.getApiPaymentStatus({id: id})
      .then(async (x: GetApiPaymentStatusResponse) => {
        return Promise.resolve(x.status);
      })
      .catch((error: any) => {
        return Promise.reject("");
      });
  } 

  async function getPaymentHistory() {
    return await PaymentService.getApiPaymentHistory({page: page.value, pageSize: pageSize.value})
      .then(async (x: GetApiPaymentHistoryResponse) => {
        history.value = x.items;
        totalPages.value = x.totalPages || 0;
        return Promise.resolve(x);
      })
      .catch((error: any) => {
        return Promise.reject("");
      });
  }

  return {
    createSession,
    getPaymentHistory,
    updatePaymentMethodSession,
    sessionStatus,
    history,
    page,
    totalPages,
    pageSize
  };
});
