import { defineStore } from "pinia";
import { ref } from "vue";
import { type GetApiSubscriptionsResponse, type SubscriptionDto } from "@/client";
import { SubscriptionsService } from "@/client";
import { request } from "@/client/core/request";

export const useSubscriptionStore = defineStore(
  "subscription",
  () => {
    const subscription = ref<SubscriptionDto>();

    const get = async () => {
      return await SubscriptionsService.getApiSubscriptions()
        .then((response: GetApiSubscriptionsResponse) => {         
            if (response != undefined) {
                subscription.value = response;
            }
            return Promise.resolve();
        })
        .catch((error:any) => {
            return Promise.reject(error);
        });
    }

    const setTrialEndDate = async (trialEnd: Date) => {
      return await SubscriptionsService.postApiSubscriptionsUpdatetrial({requestBody: {id: trialEnd.toISOString()}})
    }

    const cancel = async (reason:string) => {
      return await SubscriptionsService.postApiSubscriptionsCancel({requestBody: {id: reason}});
    }

    const restore = async () => {
      return await SubscriptionsService.postApiSubscriptionsRestore();
    }

    return {
      get,
      setTrialEndDate,
      cancel,
      restore,
      
      subscription
    };
  },
);
