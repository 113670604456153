import { ROLES } from "@/config";
import { useUserStore } from "@/stores";
import moment from "moment";

export function formatDate (d: Date | string | undefined | null, t:Boolean) {
    if (d === undefined || d === null) return "";
    d = new Date(d);
    if (!t) {
        return ('0' + d.getDate()).slice(-2) + '/'
            + ('0' + (d.getMonth()+1)).slice(-2) + '/'
            + d.getFullYear();
    } else {
        return ('0' + d.getDate()).slice(-2) + '/'
            + ('0' + (d.getMonth()+1)).slice(-2) + '/'
            + d.getFullYear()
            + " " + ('0' + d.getHours()).slice(-2) + ":" + ('0' + d.getMinutes()).slice(-2);
    } 
};
export function formatTime(time:string) {
  if (time === undefined || time === null) return "";
  var t = time.split(':');
  if (parseInt(t[0]) > 12) {
    return (parseInt(t[0]) - 12) + ':' + t[1] + ' PM';
  }
  return time.split(':').slice(0, 2).join(':') + " AM";
}
export function addDays(date: Date, days: number) {
    var date = new Date(date.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};
export function isInRole(role: string) {
    const userStore = useUserStore();
    if (userStore.user.roles.indexOf(ROLES.CHECKMATE) > -1) return true;
    return (userStore.user.roles.indexOf(role) > -1);
}
export function convertTimeToDateTime(
    time: Date | undefined | string | null,
  ): Date | undefined {
    if (time == undefined) {
      return undefined;
    }

    const today = new Date();

    if (typeof time === "string") {
      const timeParts = time.split(":");
      const hour = parseInt(timeParts[0], 10);
      const minute = parseInt(timeParts[1], 10);

      return new Date(Date.UTC(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        hour,
        minute,
        0,
      ));
    }
    return new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      time.getHours(),
      time.getMinutes(),
      0,
    );
};
export function formatToRelativeTime(d: Date | string | undefined | null) {
  if (d == undefined) return "";
  const days = moment(new Date(d)).diff(moment(), "days");
  return (days + 1) + (days > 1 ? " days" : " day");
}
