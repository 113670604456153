export default {
    root: ({ props }) => ({
        class: [
            // Misc
            { 'opacity-60 select-none pointer-events-none cursor-default': props.disabled }
        ]
    }),
    range: ({ props }) => ({
        class: [
            // Stroke
            'stroke-current',

            // Color
            'stroke-surface-200 dark:stroke-surface-700',

            // Fill
            'fill-none',

            // Transition
            'transition duration-100 ease-in',
        ]
    }),
    value: ({ props }) => ({
        class: [
            // Animation
            'animate-dash-frame',

            // Fill
            'fill-none',

            // Stroke
            { 'stroke-primary-600': props.modelValue >= 90 },
            { 'stroke-orange-600': props.modelValue < 90 && props.modelValue >= 75 },
            { 'stroke-red-600': props.modelValue < 75 },
        ]
    }),
    label: {
        class: [
            // Text Style
            'text-center text-xl',

            // Color
            'fill-surface-600 dark:fill-surface-200'
        ]
    }
};
