import {errors} from './errors';
import {global} from './global';
import { login, registration, authorize, devices, checks, issues, reports, user, settings  }  from './pages';

export const en = {
    errors,
    global,

    //  Pages
    login,
    registration,
    authorize,
    devices,
    checks,
    issues,
    reports,
    user,
    settings
};
