import { defineStore } from "pinia";
import { ref } from "vue";
import { LocationService, type CreateLocationRequest, type LocationDto, type PostApiLocationCreateResponse, type PostApiLocationUpdateResponse, type GetApiLocationGetAllResponse, type GetApiLocationGetByIdResponse, type PostApiLocationCloneResponse } from '@/client';

export const useLocationsStore = defineStore("locations", () => {
  const locations = ref<LocationDto[]>([]);

  

  async function list() {
    return await LocationService.getApiLocationGetAll()
    .then((response: GetApiLocationGetAllResponse) => {
        if (response != undefined) {
            locations.value = response as LocationDto[];
            locations.value = locations.value.sort((a, b) => a.name!.localeCompare(b.name!));
        }
        return Promise.resolve();
    })
    .catch((error:any) => {
        return Promise.reject(error);
    });
}

async function get(locationId: string) {
  return await LocationService.getApiLocationGetById({id: locationId})
  .then((response: GetApiLocationGetByIdResponse) => {
      return Promise.resolve(response as LocationDto);
  })
  .catch((error:any) => {
      return Promise.reject(error);
  });
}

async function create(location: CreateLocationRequest) {
    return await LocationService.postApiLocationCreate({requestBody: location})
    .then((response: PostApiLocationCreateResponse) => {
        return Promise.resolve(response);
    })
    .catch((error:any) => {
        return Promise.reject(error);
    });
}

async function clone(id: string) {
    return await LocationService.postApiLocationClone({requestBody: {id: id}})
    .then((response: PostApiLocationCloneResponse) => {
        return Promise.resolve();
    })
    .catch((error:any) => {
        return Promise.reject(error);
    });
}

async function update(location: LocationDto) {
    return await LocationService.postApiLocationUpdate({requestBody: location})
    .then((response: PostApiLocationUpdateResponse) => {
        return Promise.resolve(response);
    })
    .catch((error:any) => {
        return Promise.reject(error);
    });
}

  return {
    locations,
    list,
    get,
    create,
    update,
    clone
  };
});
