import { defineStore } from "pinia";
import { ref } from "vue";
import { OpenAPI, type GetApiSubscriptionTypesGetAllResponse, type PostApiSubscriptionTypesUpdateResponse, type SubscriptionTypeDto } from "@/client";
import axios from "axios";
import { SubscriptiontypesService } from "@/client";

export const useSubscriptionTypesStore = defineStore(
  "subscriptionTypes",
  () => {
    const subscriptionTypes = ref<SubscriptionTypeDto[]>();

    const getSubscriptionTypes = async () => {
      return await SubscriptiontypesService.getApiSubscriptionTypesGetAll()
        .then((response: GetApiSubscriptionTypesGetAllResponse) => {
          subscriptionTypes.value = response;
          return Promise.resolve(response);
        });
    };

    const save = async (dto:SubscriptionTypeDto) => {
      return await SubscriptiontypesService.postApiSubscriptionTypesUpdate({requestBody: dto})
        .then((response: PostApiSubscriptionTypesUpdateResponse) => {
          return Promise.resolve(response);
        });
    };

    return {
      subscriptionTypes,
      getSubscriptionTypes,
      save
    };
  },
);
