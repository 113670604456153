<script setup>
    const props = defineProps({
        type: {
            type: String,
            default: "primary"
        },
        onClick: {
            type: Function,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    })
</script>


<template>
    <button :loading="props.loading" :disabled="props.loading" @click="onClick" type="button" class="w-full block px-4 py-1.5 transition duration-100 ease-in-out focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed rounded"
        :class="{
            'bg-primary-light text-white  shadow-sm': props.type === 'secondary',
            'bg-primary text-white hover:bg-primary-light  shadow-sm': props.type === 'primary',
            'bg-white text-black hover:text-gray-500 py-0 px-0': props.type === 'link',
            'bg-red-500 text-white hover:bg-red-300 py-0 px-0': props.type === 'danger'
        }"
    >
    <div class="flex gap-2 justify-center">
        <i class="fa fa-circle-notch fa-spin" v-if="props.loading" style="font-size: 1.2em; line-height: 1.2em; height: 1.2em;"  />
        <slot>Button</slot>
    </div>

    </button>
</template>