import { defineStore } from "pinia";
import { DashboardService, type PostApiDashboardLocationResponse, type GetApiDashboardResponse } from '@/client';

export const useDashboardStore = defineStore("devicetype", () => {
    async function getDashboard(from:string, to:string) {
        return await DashboardService.getApiDashboard({from, to})
        .then((response: GetApiDashboardResponse) => {
            return Promise.resolve(response);
        })
        .catch((error:any) => {
            return Promise.reject(error);
        });
    }

    async function getStatsForLocation(from:string, to:string, location: string) {
        return await DashboardService.postApiDashboardLocation({requestBody: { from, to, location}})
        .then((response: PostApiDashboardLocationResponse) => {
            return Promise.resolve(response);
        })
        .catch((error:any) => {
            return Promise.reject(error);
        });
    }

    return {
        getDashboard,
        getStatsForLocation
    };
});