import { useSettingStore } from "@/stores/setting.store";

export enum InputTypes {
    Text,
    TextArea,
    CheckBox,
    FridgeTemp,
    FreezerTemp,
    HotHoldingTemp,
    CookingTemp,
    Signature,
    Select,
    Message,
    Timestamp,
    CoolingTemp,
    HotProbeTemp,
    ColdProbeTemp,
    ColdHoldingTemp,
    RegenerationTemp,
    CheckBoxNo,
    CustomTemperature
}

export const getInputTypeDropdownOptions = () => {
    const settings = useSettingStore();
    return [
        { text: "Text", value: 0 },
        { text: "Text area", value: 1 },
        { text: "Checkbox (Yes Expected)", value: 2 },
        { text: "Checkbox (No Expected)", value: 16 },
        { text: "Fridge Temperature " + settings.getTempRangeString(settings.settings.fridge), value: 3 },
        { text: "Freezer Temperature " + settings.getTempRangeString(settings.settings.freezer), value: 4 },
        { text: "Cooking Temperature " + settings.getTempRangeString(settings.settings.cooking), value: 6 },
        { text: "Cooling Tempeature " + settings.getTempRangeString(settings.settings.cooling), value: 11 },
        { text: "Hot Probe Temperature " + settings.getTempRangeString(settings.settings.hotProbe), value: 12 },
        { text: "Cold Probe Temperature " + settings.getTempRangeString(settings.settings.coldProbe), value: 13 },
        { text: "Cold Holding Temperature " + settings.getTempRangeString(settings.settings.coldHolding), value: 14 },
        { text: "Hot holding Temperature " + settings.getTempRangeString(settings.settings.hotHolding), value: 5 },
        { text: "Regeneration Temperature " + settings.getTempRangeString(settings.settings.regeneration), value: 15 },
        { text: "Custom Temperature", value: 17 },
        { text: "Signature", value: 7 },
        { text: "Dropdown", value: 8 },
        { text: "Message", value: 9 },
        { text: "Timestamp", value: 10 },
    ];
}

export function isTemperatureInput(type: number | undefined) : boolean {
    if (type === undefined) return false;
    return (type === InputTypes.FridgeTemp || 
        type === InputTypes.FreezerTemp || 
        type === InputTypes.HotHoldingTemp || 
        type === InputTypes.CookingTemp || 
        type === InputTypes.CoolingTemp || 
        type === InputTypes.HotProbeTemp || 
        type === InputTypes.ColdProbeTemp || 
        type === InputTypes.ColdHoldingTemp || 
        type === InputTypes.RegenerationTemp ||
        type === InputTypes.CustomTemperature
    );
}