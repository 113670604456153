import "./assets/main.css";

import router from "./router";
import { createApp, markRaw } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import moment from "moment";
import i18n from "./i18n";
import ToastService from "primevue/toastservice";
import DialogService from "primevue/dialogservice";
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import Aura from "@/presets/aura";
import "nprogress/nprogress.css";
import Tooltip from "primevue/tooltip";
import axios from "axios";
import { requestError, requestProgress } from "./interceptors/request";
import { responseError, responseReturned } from "./interceptors/response";
import {useSettingStore, useSubscriptionStore, useLocationsStore} from "@/stores/";
import * as Sentry from "@sentry/vue";
import { OpenAPI } from "@/client";
import VueGtag from "vue-gtag";

OpenAPI.BASE = import.meta.env.VITE_API_URL;

moment.locale("en");

export const app = createApp(App);
const pinia = createPinia();
pinia.use(({ store }) => {
  store.router = markRaw(router);
});

Sentry.init({
  app,
  dsn: "https://873d9189b2afc6039aa56c0a49b02eed@o4508065373421568.ingest.de.sentry.io/4508065390002256",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/admin.checkmate.software/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.use(PrimeVue, {
  unstyled: true,
  pt: Aura,
  ripple: true,
});
app.use(pinia);
app.use(router);
app.use(i18n);
app.use(ToastService);
app.use(DialogService);
app.use(ConfirmationService);
app.directive("tooltip", Tooltip);
app.use(VueGtag, {
  config: { id: "G-PY3Q80LT6T" }
});

const authorization = JSON.parse(localStorage.getItem("auth")!);
if (authorization) {
  axios.defaults.headers.common = {
    Authorization: `bearer ${authorization.token}`,
  };
  const settingStore = useSettingStore();
  settingStore.get();
  const subscriptionStore = useSubscriptionStore();
  subscriptionStore.get();
  const locationStore = useLocationsStore();
  locationStore.list();
}

app.mount("#app");

axios.interceptors.request.use(requestProgress, requestError);
axios.interceptors.response.use(responseReturned, responseError);